import __request__ from 'routing/request';
interface PaginatedResponse_ViewDTO {
    readonly [_key: string]: Object;
}
interface BulkOperationResponse {
    readonly failures: BulkOperationFailure[];
    readonly successfully_performed: number;
    readonly errors: string[];
}
interface ViewStateDTO {
    readonly static_message_list_id: string;
    readonly widget_mapping: {
        readonly [_key: string]: string[];
    };
    readonly display_mode_settings: DisplayModeSettings;
    readonly positions: {
        readonly [_key: string]: WidgetPositionDTO;
    };
    readonly titles: {
        readonly [_key: string]: {
            readonly [_key: string]: string;
        };
    };
    readonly widgets: WidgetDTO[];
    readonly selected_fields: string[];
    readonly formatting: FormattingSettings;
}
interface UsedSearchFilter {
}
interface BulkOperationFailure {
    readonly failure_explanation: string;
    readonly entity_id: string;
}
interface Position {
}
interface WidgetConfigDTO {
}
interface PluginMetadataSummary {
    readonly unique_id: string;
    readonly author: string;
    readonly name: string;
    readonly description: string;
    readonly version: unknown;
    readonly url: string;
}
interface DisplayModeSettings {
    readonly positions: {
        readonly [_key: string]: WidgetPositionDTO;
    };
}
interface WidgetDTO {
    readonly filter: string;
    readonly timerange: TimeRange;
    readonly query: BackendQuery;
    readonly streams: string[];
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly type: string;
    readonly config: WidgetConfigDTO;
}
interface TimeRange {
    readonly type: string;
}
interface ViewDTO {
    readonly owner: string;
    readonly summary: string;
    readonly created_at: string;
    readonly description: string;
    readonly id: string;
    readonly state: {
        readonly [_key: string]: ViewStateDTO;
    };
    readonly type: 'SEARCH' | 'DASHBOARD';
    readonly title: string;
    readonly favorite: boolean;
    readonly properties: string[];
    readonly search_id: string;
    readonly requires: {
        readonly [_key: string]: PluginMetadataSummary;
    };
}
interface HighlightingRule {
    readonly condition: 'equal' | 'not_equal' | 'greater' | 'greater_equal' | 'less' | 'less_equal';
    readonly field: string;
    readonly color: HighlightingColor;
    readonly value: string;
}
interface BulkOperationRequest {
    readonly entity_ids: string[];
}
interface HighlightingColor {
    readonly type: string;
}
interface WidgetPositionDTO {
    readonly col: Position;
    readonly width: Position;
    readonly row: Position;
    readonly height: Position;
}
interface Object {
}
interface FormattingSettings {
    readonly highlighting: HighlightingRule[];
}
interface BackendQuery {
}
/**
 * Create a new view
 */
export function create(arg0?: ViewDTO): Promise<ViewDTO> {
    return __request__('POST', '/views', arg0, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of all views
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function views(sort: 'id' | 'title' | 'created_at' = 'title', page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'asc', query?: string): Promise<PaginatedResponse_ViewDTO> {
    return __request__('GET', '/views', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a bulk of views
 */
export function bulkDelete(Entitiestoremove: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/views/bulk_delete', Entitiestoremove, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single view
 */
export function get(id?: string): Promise<ViewDTO> {
    return __request__('GET', `/views/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update view
 */
export function update(id?: string, arg1?: ViewDTO): Promise<ViewDTO> {
    return __request__('PUT', `/views/${id}`, arg1, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete view
 */
export function remove(id?: string): Promise<ViewDTO> {
    return __request__('DELETE', `/views/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Configures the view as default view
 */
export function setDefault(id?: string): Promise<void> {
    return __request__('PUT', `/views/${id}/default`, null, {}, {
        'Accept': ['application/json']
    });
}
